:local(.page) {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: white;

  :global {
    div.title {
      padding: 25px 0;
      text-align: center;
    }

    div.action {
      text-align: center;
      button.purchase {
        width: 40%;
        font-size: 18px;
      }
      button.cancel {
        margin-top: 20px;
      }
    }

    div.price {
      padding: 30px 0;
      text-align: center;
    }

    div.amazonPayWidget {
      height: 300px;
      margin: 10px 10px;
    }

    div.saving-mask {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
      background-color: rgba(black, 0.6);

      button.loading {
        position: absolute;
        top: calc(50% - 12px);
        left: calc(50% - 12px);
      }
    }
  }
}
