div.container {
  position: relative;
  width: 100%;
  height: 100%;

  div.explanation {
    position: absolute;
    top: 15vh;
    right: 32px;
    left: 32px;
  }
  div.tap-text {
    position: absolute;
    padding: 16px 12px;
    bottom: 0;
    right: 0;
    font-size: 12px;
  }
}
