div.story-header {
  position: absolute;
  width: 100%;
  padding: 16px 10px;

  #progressbar {
    width: 44px;
    height: 44px;
    padding: 12px;
  }

  div.reload {
    img {
      width: 44px;
      height: 44px;
      object-fit: contain;
      padding: 12px;
    }
  }
  div.score {
    line-height: 44px;
    font-size: 15px;
  }

  div.close {
    img {
      width: 44px;
      height: 44px;
      object-fit: contain;
      padding: 13px;
    }
  }
}
