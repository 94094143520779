:local(.component) {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.86);
  :global {
    div.close {
      position: absolute;
      right: 10px;
      top: 15px;
      img {
        width: 44px;
        height: 44px;
        object-fit: contain;
        padding: 13px;
      }
    }
    p.text {
      position: absolute;
      top: 20%;
      left: 0;
      right: 0;
      text-align: center;
    }
    div.center {
      position: absolute;
      top: 45%;
      left: 0;
      right: 0;
      text-align: center;
      span {
        border-bottom: solid 3px white;
        padding-bottom: 5px;
      }
    }

    div.button-wapper {
      position: absolute;
      top: 70%;
      left: 0;
      right: 0;
      margin: auto;
      text-align: center;
      button.guest-start {
        width: 65%;
        height: 40px;
        font-size: 14px;
        font-weight: bold;
        background-color: rgba(64, 173, 169, 1);
        border: none;
      }
      button.regist {
        margin-top: 30px;
        text-decoration: none;
      }
    }
  }
}
