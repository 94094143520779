:local(.component) {
  border-bottom: 1px solid #ddd;
  height: 44px;

  :global {
    div.left {
      margin: 6px 0 6px 8px;
      height: 44px;
      display: inline-block;

      a.navbar-item {
        margin-top: auto;
        margin-bottom: auto;
        padding-top: 0;
        padding-bottom: 0;

        figure {
          padding: 0;

          img {
            width: 32px;
            height: 32px;
            max-height: 32px;
          }
        }
      }
    }

    div.center {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      display: inline-block;
      text-align: center;

      span {
        line-height: 44px;
      }
    }

    div.right {
      position: absolute;
      top: 0;
      right: 0;
      height: 44px;

      button {
        width: auto;
        height: 30px;
        margin: 6px 10px 6px auto;
        text-align: center;

        &.account {
          padding: 0;
        }
      }

      figure.account {
        margin: 6px 10px 6px auto;
        padding: 0;

        &:hover {
          cursor: pointer;
        }

        img {
          object-fit: cover;
          width: 32px;
          height: 32px;
        }
      }
    }
  }
}
