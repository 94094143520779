:local(.alert) {
  position: relative;
  width: 100%;
  height: 100%;
  :global {
    div.modal-content {
      height: 100vh;

      div.dialog {
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: auto;
        height: fit-content;

        width: 80%;
        background-color: rgba(255, 255, 255, 0.9);
        border-radius: 10px;

        div.title-dialog {
          position: relative;
          text-align: center;
          padding: 15px 10px 10px;
          font-size: 16px;
          font-weight: bold;
        }
        div.message-dialog {
          position: relative;
          text-align: center;
          vertical-align: middle;
          font-size: 14px;
          padding: 0px 16px 20px 16px;
        }
        div.button-dialog {
          position: relative;
          height: 50px;
          border-top: 1px rgba(128, 128, 128, 0.7);
          border-top-style: solid;
        }

        div.columns {
          height: 100%;
          div.button-cancel {
            font-size: 16px;
            border-right: 1px rgba(128, 128, 128, 0.7);
            border-right-style: solid;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          div.button-ok {
            font-size: 16px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
        }

        div.text-input {
          margin: 0px 8px 20px;
          font-size: 14px;
        }
      }

      textarea {
        height: 200px;
        font-size: 14px;
      }
    }
  }
}
