:local(.container) {
  position: relative;
  width: 100%;
  height: 100%;

  :global {
    img.background {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      object-fit: cover;
      height: 100%;
      height: 100%;
      width: 100%;
    }

    div.share {
      position: absolute;
      top: 30px;
      left: 20px;
      button {
        border: 1px solid #ffffff;
        border-radius: 5px;
        text-decoration: none;
        font-size: 13px;
      }
    }

    div.background-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background-color: rgba(#000000, 0.2);
    }

    div.black-mask {
      position: absolute;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        to bottom,
        rgba(#000000, 0.3),
        rgba(#000000, 0) 50%,
        rgba(#000000, 0.3)
      );
    }

    div.title-wrapper {
      position: absolute;
      top: 95px;
      left: 20px;
      div.story-title {
        margin-top: 75px;
      }
    }

    button.userName {
      position: absolute;
      text-decoration: underline;
      font-size: 12px;
      top: 20px;
      right: 10px;
    }

    figure.account {
      position: absolute;
      top: 20px;
      right: 10px;
      margin: 6px 10px 6px auto;
      padding: 0;

      &:hover {
        cursor: pointer;
      }

      img {
        object-fit: cover;
        width: 48px;
        height: 48px;
      }
    }

    div.story-top-footer {
      position: absolute;
      width: 100%;
      height: fit-content;
      text-align: center;
      bottom: 5vh;
      animation: start-button-animation 0.5s
        cubic-bezier(0.54, 0.04, 0.35, 1.55) forwards;

      div.text-caution {
        margin: 5px;
        font-size: 13px;
      }

      button.trial {
        position: relative;
        width: 70%;
        height: 45px;
      }

      div.purchase {
        position: relative;
        font-size: 17px;
        margin-top: 20px;
      }
    }

    @keyframes :global(start-button-animation) {
      0% {
        transform: scale(0);
      }
      100% {
        transform: scale(1);
      }
    }
  }
}
