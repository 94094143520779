:local(.mobile) {
    position: relative;
    width: 100%;
    height: 100%;
}

:local(.not_mobile) {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: black;

    :global {
        img.background-image {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            opacity: 0.4;
        }

        div.iphone-image {
            position: absolute;
            background-image: url(/assets/images/iphone_8.png);
        }

        div.contents {
            position: absolute;
            overflow-x: hidden;
            overflow-y: hidden;

            &.scrollable {
                overflow-y: auto;
            }
        }
    }
}