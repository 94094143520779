div.keyword-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  div.fullscreen-panel {
    width: 100%;
    height: 100%;
  }

  div.keyword {
    position: absolute;
    top: 48%;
    text-align: center;
    padding: 0 32px;
    left: 0;
    right: 0;
    pointer-events: none;

    div.input-wrapper {
      pointer-events: auto;
      position: relative;
      padding: 20px;

      input.input-keyword {
        position: relative;
        background-color: rgba(255, 255, 255, 1);
        pointer-events: auto;
      }
    }

    div.decision {
      margin-top: 13vh;
      div.button-wrapper {
        pointer-events: auto;
        position: relative;
        display: inline-block;
        padding: 20px;
        button.button-decision {
          position: relative;
          width: 100px;
        }
      }
      div.error {
        height: 40px;
        color: red;
        padding: 10px;
        font-size: 12px;
      }
    }
  }
}
