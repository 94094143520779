:local(.component) {
  margin: 20px 20px 0 20px;
  background-color: white;

  &:hover {
    cursor: pointer;
  }
  
  :global {

    div.subtitle {
      margin-bottom: 0;

      &.price {
        margin: 10px 0;
      }
    }

    figure {
      width: 100px;
      height: 100px;

      img {
        object-fit: cover;
        width: 100px;
        height: 100px;
      }
    }

    div.media-content {

      i {
        position: absolute;
        top: 10px;
        right: 10px;
      }
      div.score {
        position: absolute;
        bottom: 10px;
        right: 10px;

        span.score-unit {
          margin-left: 4px;
        }
      }
    }
  }
}