:local(.reviewRowComponent) {

    :global {
        img {
            width: 20px;
            height: 20px;
            object-fit: contain;
            margin-right: 5px;
            vertical-align: top;
        }

        span.number-of-reviews {
            color: white;
            line-height: 20px;
            font-weight: bold;
            font-size: 0.8em;
        }
    }

}
