:local(.component) {
  margin-top: 20px;
  background-color: white;
  
  :global {

    figure.image {
      position: relative;

      img {
        object-fit: cover;
      }

      div.black-mask {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: black;
        opacity: 0.2;
      }

      div.story-title {
        position: absolute;
        top: calc(50% - 15px);
        left: 0;
        width: 100%;
        text-align: center;
        line-height: 30px;
      }
    }

    div.action {
      margin-top: 10px;
      text-align: center;

      button {
        width: 50%;
        font-size: 12px;
      }
    }
  }
}