:local(.component) {
  position: relative;
  width: 100%;
  height: 100%;
  background-color: rgba(10, 10, 10, 0.86);
  :global {
    div.close {
      position: absolute;
      right: 10px;
      top: 15px;
      img {
        width: 44px;
        height: 44px;
        object-fit: contain;
        padding: 13px;
      }
    }
    p.text {
      position: absolute;
      top: 20%;
      left: 0;
      right: 0;
      text-align: center;
    }
    div.center {
      position: absolute;
      top: 45%;
      left: 0;
      right: 0;
      text-align: center;
      p.campaign {
        font-size: 26px;
      }
    }
    button {
      position: absolute;
      top: 70%;
      left: 0;
      right: 0;
      margin: auto;
      width: 65%;
      height: 50px;
      font-size: 16px;
      font-weight: bold;
      background-color: #40ada9;
      border: none;
      color: white;
    }
  }
}
