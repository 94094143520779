div.result-component {
  position: absolute;
  top: 20vh;
  bottom: 20vh;
  padding: 40px;
  width: 100%;

  text-align: center;

  div.tile {
    width: 90%;
    margin: auto;
  }

  div.box {
    padding: 35px;
    background-color: rgba($color: #ffffff, $alpha: 0.8);

    &.score {
      div.score {
        position: relative;
        margin: 20px 0;
        padding: 0;
        text-align: center;

        span.score-point {
          font-size: 60px;
          font-weight: bold;
        }

        span.score-suffix {
          margin-left: 8px;
          font-size: 30px;
          font-weight: bold;
        }

        div.border-line {
          position: absolute;
          bottom: 0;
          left: calc(50% - 60px);
          display: inline-block;
          height: 4px;
          width: 120px;
          background-color: black;
        }
      }

      div.share {
        margin-top: 20px;
        margin-bottom: 4px;

        &.no-score {
          margin-top: 60px;
          margin-bottom: 60px;
        }

        button {
          background: transparent;
          color: #444;
          border: 1px solid #888;
        }
      }

      div.next {
        margin-top: 16px;
      }
    }

    &.review {
      p {
        font-size: 0.8em;
      }

      button {
        height: 100%;
        margin: 16px 20px 0;
        padding: 0 20px;
        background-color: rgba($color: #000000, $alpha: 0.7);

        img {
          margin-top: 3px;
          width: 100%;
          padding: 10%;
        }
      }
    }

    &.comment {
      p {
        font-size: 0.8em;
      }

      textarea {
        margin: 16px 0 0;
        padding: 10px;
        width: 100%;
        height: 200px;
        font-size: 0.8em;
      }

      div.next {
        margin-top: 8px;
      }
    }

    &.end {
      div.next {
        position: relative;
        width: 100%;
        height: 300px;

        button {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          margin: auto;
        }
      }
    }
  }

  div.regist {
    text-align: center;
    button {
      margin-top: 24px;
      height: 40px;
      font-size: 14px;
      font-weight: bold;
      background-color: #40ada9;
      border: none;
      color: white;
    }
  }

  p {
    font-weight: bold;
    text-align: center;
  }

  div.follow {
    margin-top: 32px;
    width: 100%;

    button {
      background-color: rgba($color: #ffffff, $alpha: 0.8);
      width: 100%;
    }
  }
}
