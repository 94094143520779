div.addPoint-wrapper {
  position: absolute;
  top: 10%;
  animation: addPoint-anime 2s ease;
  opacity: 0;

  div.point-add {
    background-color: rgba(72, 192, 169, 0.7);
    width: 180px;
  }

  div.point-subtract {
    background-color: rgba(255, 87, 87, 0.7);
    width: 180px;
  }
}

@keyframes addPoint-anime {
  0% {
    opacity: 1;
    left: -50%;
  }
  30% {
    opacity: 1;
    left: 0;
  }
  80% {
    opacity: 1;
    left: 0;
  }
  100% {
    opacity: 0;
    left: 0;
  }
}
